import type {TComponentColor, TIcons, TTestIDs} from '@mcal/core-react';
import {cn, getColorPalette, useTheme} from '@mcal/core-react';
import type {ElementType, FC} from 'react';
import {useMemo} from 'react';
import {createTestIDs} from '../../dev/index.js';
import {AddSvg} from '../../icons/add.svg.js';
import {ApartmentSvg} from '../../icons/apartment.svg.js';
import {BackSvg} from '../../icons/back.svg.js';
import {CallSvg} from '../../icons/call.svg.js';
import {CheckSvg} from '../../icons/check.svg.js';
import {CloseSvg} from '../../icons/close.svg.js';
import {DistanceSvg} from '../../icons/distance.svg.js';
import {DoorSlidingSvg} from '../../icons/door-sliding.svg.js';
import {DoubleChevronSvg} from '../../icons/double-chevron.svg.js';
import {EmergencySvg} from '../../icons/emergency-tsx.js';
import {ExploreSvg} from '../../icons/explore.svg.js';
import {GraphicSvg} from '../../icons/graphic.svg.js';
import {NormalSvg} from '../../icons/normal.js';
import {ProblemSvg} from '../../icons/problem.svg.js';
import {ServicesToolboxSvg} from '../../icons/service-company.svg.js';
import {SettingsSvg} from '../../icons/settings.svg.js';
import {SyncSvg} from '../../icons/sync.svg.js';
import {TeamSvg} from '../../icons/team.svg.js';
import {TechnicianSvg} from '../../icons/technician.svg.js';
import {ThrashSvg} from '../../icons/thrash.svg.js';
import {UploadSvg} from '../../icons/upload.svg.js';
import {UserSvg} from '../../icons/user.svg.js';
import {StyledRoot} from './icon.styles.js';

const ownTestIDs = createTestIDs('Icon', ['root']);

const ICON_SVG: Record<TIcons, ElementType> = {
    add: AddSvg,
    apartment: ApartmentSvg,
    back: BackSvg,
    call: CallSvg,
    check: CheckSvg,
    distance: DistanceSvg,
    doorSliding: DoorSlidingSvg,
    doubleChevronSvg: DoubleChevronSvg,
    emergency: EmergencySvg,
    explore: ExploreSvg,
    normal: NormalSvg,
    service: GraphicSvg,
    servicesToolbox: ServicesToolboxSvg,
    settings: SettingsSvg,
    team: TeamSvg,
    thrash: ThrashSvg,
    upload: UploadSvg,
    problem: ProblemSvg,
    close: CloseSvg,
    sync: SyncSvg,
    user: UserSvg,
    technician: TechnicianSvg
};

const SIZE = {
    small: '20px',
    medium: '36px',
    large: '42px'
};

interface IIconProps {
    component?: ElementType;
    inline?: boolean;
    className?: string;
    testIDs?: TTestIDs<typeof ownTestIDs>;
    classes?: {root?: string};
    icon: TIcons;
    color?: TComponentColor | 'default';
    backgroundColor?: TComponentColor;
    withBackground?: boolean;
    rounded?: boolean;
    emphasis?: 'low' | 'high';
    level?: 'base' | 'onBase';
    size?: 'small' | 'medium' | 'large';
    backgroundEmphasis?: 'low' | 'high';
    backgroundLevel?: 'base' | 'onBase';
    backgroundOpacity?: number;
    width?: string;
    padding?: string;
    reverse?: boolean;
}

const Icon: FC<IIconProps> = ({
    component: Component = 'div',
    inline,
    testIDs = ownTestIDs,
    className = '',
    classes = {},
    icon,
    width = '24px',
    color = 'neutral',
    withBackground,
    rounded = true,
    emphasis = 'low',
    level = 'base',
    size = 'small',
    backgroundColor = color,
    backgroundEmphasis = emphasis,
    backgroundLevel = level,
    backgroundOpacity,
    padding = '4px',
    reverse
}) => {
    const theme = useTheme();
    const IconSvg = useMemo(() => ICON_SVG[icon], [icon]);
    const iconSize = useMemo(() => SIZE[size], [size]);

    return (
        <StyledRoot
            as={Component}
            data-testid={testIDs.root}
            className={cn(className, classes.root)}
            withBackground={withBackground}
            inline={inline}
            color={backgroundColor === 'default' ? 'neutral' : backgroundColor}
            emphasis={backgroundEmphasis}
            level={backgroundLevel}
            rounded={rounded}
            opacity={backgroundOpacity}
            $width={width}
            padding={padding}
        >
            <IconSvg
                reverse={reverse}
                width={iconSize}
                height={iconSize}
                fill={
                    color === 'default'
                        ? null
                        : getColorPalette(theme, color, emphasis)[level]
                }
            />
        </StyledRoot>
    );
};

export type {IIconProps};
export {Icon, ownTestIDs as testIDs};
